import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../services/api";
import { apiEndpoints } from "../../utils/constants/apiEndPoints";
import { toast } from "react-toastify";

export const sendMessage = createAsyncThunk(
  "chats/sendMessage",
  async ({ message }, { rejectWithValue, getState }) => {
    const token = getState().user.token;
    const chat_id = getState().chats?.activeChat?.chat_id;
    const { selectedDocs } = getState().docs;
    const { selectedStock } = getState().stocks;
    const url = apiEndpoints.SEND_CHAT_MESSAGE;

    if (!message) {
      return rejectWithValue("Message is required");
    }
    let isNewChat = false;
    if (chat_id == null) {
      isNewChat = true;
    }
    if (isNewChat) {
      if (selectedStock == null && !selectedDocs.length) {
        toast.clearWaitingQueue();
        toast.dismiss();

        toast.error("Please select a stock ticker or document");
        return rejectWithValue(
          "Please select a document or stock ticker rejectWithValue"
        );
      }
    }

    const body = {
      chat_id,
      message,
      stock_ticker: selectedStock?.ticker,
      document_ids: selectedDocs,
    };
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const result = await api.post(url, body, { headers });
      return { data: result?.data, isNewChat };
    } catch (error) {
      toast.clearWaitingQueue();
      toast.dismiss();

      toast.error(
        error.response?.data?.message ||
          error.message ||
          "Failed to send message"
      );
      return rejectWithValue(
        error.response?.data?.message ||
          error.message ||
          "Failed to send message"
      );
    }
  }
);
