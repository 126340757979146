import styles from "./myData.module.css";
import { useEffect, useState, useCallback, useMemo } from "react";
import UploadDocument from "../../../components/UploadDocument";
import { IoFilter } from "react-icons/io5";
import Filter from "../../../components/Filter";
import { getAllDocs, setSelectDocs } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { useAction } from "../../../hooks/useActions";
import Skeleton from "../../skeleton";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";

const MyData = ({ handleShowPDF }) => {
  const dispatch = useDispatch();
  const { allDocs, selectedDocs } = useSelector((state) => state.docs);
  const { activeChat } = useSelector((state) => state.chats);
  const { selectedStock } = useSelector((state) => state.stocks);
  const [getAllDocsAction, , isDocsLoading] = useAction(getAllDocs);
  const [selectedOption, setSelectedOption] = useState("All Time");
  const [allSelected, setAllSelected] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [expandedItems, setExpandedItems] = useState({});

  useEffect(() => {
    getAllDocsAction({ stock_ticker: selectedStock?.ticker });
  }, [selectedStock]);

  useEffect(() => {
    if (Array.isArray(selectedDocs)) {
      setAllSelected(
        allDocs?.every((item) =>
          item?.content?.every((content) => selectedDocs.includes(content.id))
        )
      );
    }
  }, [selectedDocs, allDocs]);

  const handleSelectAll = useCallback(() => {
    const newSelectedItems = !allSelected
      ? allDocs.flatMap((item) => item.content.map((content) => content.id))
      : [];
    dispatch(setSelectDocs(newSelectedItems));
  }, [allDocs, allSelected, dispatch]);

  // Handle selection of individual items or categories
  const handleItemSelect = useCallback(
    (index, contentId = null) => {
      let newSelectedItems = [...(selectedDocs || [])];
      const allDocsSelected = allDocs[index].content.map(
        (content) => content.id
      );

      if (contentId === null) {
        const allCategorySelected = allDocsSelected.every((docId) =>
          newSelectedItems.includes(docId)
        );
        newSelectedItems = allCategorySelected
          ? newSelectedItems.filter((docId) => !allDocsSelected.includes(docId))
          : [...new Set([...newSelectedItems, ...allDocsSelected])];
      } else {
        const keyIndex = newSelectedItems.indexOf(contentId);
        if (keyIndex > -1) {
          newSelectedItems.splice(keyIndex, 1);
        } else {
          newSelectedItems.push(contentId);
        }
      }

      dispatch(setSelectDocs(newSelectedItems));
    },
    [allDocs, selectedDocs, dispatch]
  );

  // Toggle expand/collapse for categories
  const handleToggle = useCallback(
    (index) => {
      setExpandedItems((prev) => ({ ...prev, [index]: !prev[index] }));
    },
    [setExpandedItems]
  );

  // Handle filter toggle
  const toggleFilter = () => setShowFilter(!showFilter);

  // Handle filter application
  const filterHandler = ({ option, dateRange }) => {
    getAllDocsAction(dateRange);
    setSelectedOption(option);
    setShowFilter(false);
  };

  const docsContent = useMemo(() => {
    if (isDocsLoading) {
      return <Skeleton width="100%" height="30px" gap="15px" />;
    }

    if (!allDocs?.length) {
      return <div className={styles.noData}>Add Data +</div>;
    }

    return (
      <div className={styles.docs_wrapper}>
        {allDocs.map((item, index) => (
          <div key={index}>
            <div className={styles.doc_wrapper}>
              <div className={styles.checkbox_wrapper}>
                <input
                  className={styles.checkbox}
                  disabled={activeChat != null}
                  type="checkbox"
                  checked={item?.content?.every((content) =>
                    Array.isArray(selectedDocs)
                      ? selectedDocs.includes(content.id)
                      : false
                  )}
                  onChange={() => handleItemSelect(index)}
                />
                <label
                  onClick={() => handleToggle(index)}
                  className={styles.label}
                >
                  {item?.category}
                </label>
              </div>
              {expandedItems[index] ? (
                <FaAngleUp onClick={() => handleToggle(index)} />
              ) : (
                <FaAngleDown onClick={() => handleToggle(index)} />
              )}
            </div>
            {expandedItems[index] &&
              item?.content?.map((content) => (
                <div key={content.id} className={styles.expanded_content}>
                  <div className={styles.checkbox_wrapper}>
                    <input
                      disabled={activeChat != null}
                      className={styles.checkbox}
                      type="checkbox"
                      checked={
                        Array.isArray(selectedDocs)
                          ? selectedDocs.includes(content.id)
                          : false
                      }
                      onChange={() => handleItemSelect(index, content.id)}
                    />
                    <label
                      className={styles.label}
                      onClick={() => handleShowPDF(content?.link)}
                    >
                      {content.title}
                    </label>
                  </div>
                </div>
              ))}
          </div>
        ))}
      </div>
    );
  }, [
    isDocsLoading,
    allDocs,
    expandedItems,
    selectedDocs,
    handleToggle,
    handleItemSelect,
  ]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.heading}>
        <div className={styles.checkbox_wrapper}>
          <input
            className={styles.checkbox}
            type="checkbox"
            checked={allSelected}
            onChange={handleSelectAll}
            disabled={activeChat != null}
          />
          <label className={styles.label}>My Data</label>
        </div>
        <div className={styles.filter}>
          <IoFilter
            size={20}
            className={styles.filterIcon}
            onClick={toggleFilter}
          />
          <UploadDocument />
          {showFilter && (
            <Filter
              selectedOption={selectedOption}
              filterHandler={filterHandler}
            />
          )}
        </div>
      </div>

      <>{docsContent}</>
    </div>
  );
};

export default MyData;
