import { createAsyncThunk } from "@reduxjs/toolkit";
import { setSelectedStock } from "../slices/stocksSlice";
import { setSelectDocs } from "../slices/docsSlice";
import { clearActiveChat } from "../slices/chatsSlice";

export const setNewChat = createAsyncThunk(
  "chats/setNewChat",
  async (_, { dispatch }) => {
    // Dispatch the action to clear selectedStock
    dispatch(setSelectedStock(null));
    dispatch(setSelectDocs([]));
    dispatch(clearActiveChat());
  }
);
