import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../services/api";
import { apiEndpoints } from "../../utils/constants/apiEndPoints";
import { getAllDocs } from "./getAllDocs";
import { toast } from "react-toastify";

export const uploadFiles = createAsyncThunk(
  "docs/upload",
  async (filesData, { rejectWithValue, getState, dispatch }) => {
    const url = apiEndpoints.FILE_UPLOAD;
    const formData = new FormData();

    const token = getState().user.token;
    const { selectedStock } = getState().stocks;
    filesData.forEach((data) => {
      formData.append("files", data.file);
      formData.append("categories", data.type);
      formData.append("stocks", data.stock);
    });
    // BUG: I need to send array of obj or arr of strings of stocks while uploading
    try {
      const { data } = await api.post(url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (error) {
      toast.clearWaitingQueue();
      toast.dismiss();

      toast.error(
        error.response.data || error.message || "Failed to upload docs"
      );
      return rejectWithValue(
        error.response.data || error.message || "Failed to upload docs"
      );
    } finally {
      dispatch(getAllDocs({ stock_ticker: selectedStock?.ticker }));
    }
  }
);
