import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../services/api";
import { apiEndpoints } from "../../utils/constants/apiEndPoints";
import { toast } from "react-toastify";

export const getAllStockTickers = createAsyncThunk(
  "stocks/all",
  async (query, { rejectWithValue, getState }) => {
    const url = apiEndpoints.GET_ALL_STOCK_TICKERS;

    const token = getState().user.token;

    const body = {
      query: query,
    };

    try {
      const { data } = await api.post(url, body, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error) {
      toast.clearWaitingQueue();
      toast.dismiss();

      toast.error(
        error.response?.data || error.message || "Failed to fetch stock tickers"
      );
      return rejectWithValue(
        error.response?.data || error.message || "Failed to fetch stock tickers"
      );
    }
  }
);
