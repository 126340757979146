import config from "../config";

export const apiEndpoints = {
  FILE_UPLOAD: `${config.API_URL}/upload`,
  GET_ALL_CHATS: `${config.API_URL}/chat`,
  GET_STOCK_INFO: `${config.API_URL}/stock/search`,
  GET_ALL_DOCS: `${config.API_URL}/document`,
  GET_CHAT_HISTORY: (chat_id) => `${config.API_URL}/chat/${chat_id}`,
  GET_CHAT_MESSAGE_SUGGESTIONS: (chat_id) =>
    `${config.API_URL}/chat/suggested_questions/${chat_id ? chat_id : ""}`,
  SEND_CHAT_MESSAGE: `${config.API_URL}/chat`,
  LOGIN_USER: `${config.API_URL}/auth/login`,
  GET_ALL_STOCK_TICKERS: `${config.API_URL}/stock/search`,
};
