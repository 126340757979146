import React from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import config from "../utils/config";
import { loginUser } from "../store";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const GoogleLoginComponent = () => {
  const dispatch = useDispatch();

  const handleLoginSuccess = async (response) => {
    const credential = response.credential;

    dispatch(loginUser(credential));
  };

  const handleLoginFailure = (response) => {
    toast.dismiss();
    toast.clearWaitingQueue();
    toast.error("Login Failed");
    // TODO: Add some toast with error message...
  };

  return (
    <GoogleOAuthProvider clientId={config.GOOGLE_AUTH_CLIENT_ID}>
      <GoogleLogin
        onSuccess={handleLoginSuccess}
        onFailure={handleLoginFailure}
        cookiePolicy={"single_host_origin"}
        size="large"
        shape="pill"
      />
    </GoogleOAuthProvider>
  );
};

export default GoogleLoginComponent;
