import React, { useState, useEffect, useRef } from "react";
import { getAllStockTickers } from "../store";
import { useDispatch } from "react-redux";
import styles from "../styles/searchBar.module.css";
import { IoClose } from "react-icons/io5";

const SearchBar = ({
  width = "300px",
  containerStyle = {},
  onSelect,
  placeholder,
}) => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedTicker, setSelectedTicker] = useState("");
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [hasSelectedTicker, setHasSelectedTicker] = useState(false);
  const resultRefs = useRef([]); // Ref array for each result item

  const fetchTickers = async () => {
    setLoading(true);
    try {
      const response = await dispatch(getAllStockTickers(query)).unwrap();
      const fetchedResults = response?.stocks || [];
      setResults(fetchedResults);
      if (fetchedResults.length > 0) {
        setSelectedIndex(0); // Select the first element by default
      } else {
        setSelectedIndex(-1); // Reset when no results
      }
    } catch (error) {
      console.error("Error fetching stock tickers:", error);
      setResults([]);
      setSelectedIndex(-1);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const id = setTimeout(() => {
      if (query.length >= 2 && !hasSelectedTicker) {
        fetchTickers(query);
      }
    }, 500);
    return () => clearTimeout(id);
  }, [query, hasSelectedTicker]);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      setSelectedIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % results.length;
        if (resultRefs.current[newIndex]) {
          resultRefs.current[newIndex].scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
        }
        return newIndex;
      });
    } else if (e.key === "ArrowUp") {
      setSelectedIndex((prevIndex) => {
        const newIndex = (prevIndex - 1 + results.length) % results.length;
        if (resultRefs.current[newIndex]) {
          resultRefs.current[newIndex].scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
        }
        return newIndex;
      });
    } else if (e.key === "Enter" && selectedIndex >= 0) {
      const result = results[selectedIndex];
      setSelectedTicker(result?.company_name);
      onSelect(result);
      setQuery("");
      setResults([]);
      setHasSelectedTicker(true);
    }
  };

  const handleResultClick = (result) => {
    setSelectedTicker(result?.company_name);
    onSelect(result);
    setQuery("");
    setResults([]);
    setHasSelectedTicker(true);
  };

  const clearInput = () => {
    setQuery("");
    setSelectedTicker("");
    onSelect(null);
    setResults([]);
    setHasSelectedTicker(false); // Reset the selection flag
  };

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
  };

  return (
    <div className={styles.searchBar} style={{ width, ...containerStyle }}>
      <input
        type="text"
        value={selectedTicker || query}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        placeholder={placeholder}
        className={styles.input}
      />
      {selectedTicker && (
        <IoClose size={20} className={styles.clearIcon} onClick={clearInput} />
      )}
      {loading ? (
        <div className={`${styles.resultsContainer} ${styles.noData}`}>
          Loading...
        </div>
      ) : (
        isInputFocused &&
        !hasSelectedTicker && // Show suggestions only when the input is focused and no ticker is selected
        results.length > 0 && (
          <div className={styles.resultsContainer}>
            {results.map((result, index) => (
              <div
                key={result.stock_id}
                ref={(el) => (resultRefs.current[index] = el)} // Assign ref to each item
                className={`${styles.resultItem} ${
                  index === selectedIndex && styles.selectedResultItem
                }`}
                onMouseDown={() => handleResultClick(result)} // Use onMouseDown to prevent input blur before click
              >
                <div className={styles.ticker_info}>
                  <span className={styles.company_name}>
                    {result?.company_name}
                  </span>
                  <span className={styles.ticker_name}> {result?.ticker}</span>
                </div>
                <div>{result?.country ?? "Not found"}</div>
              </div>
            ))}
          </div>
        )
      )}
      {isInputFocused &&
        !hasSelectedTicker &&
        query.length > 1 &&
        results.length === 0 &&
        loading === false && (
          <div className={`${styles.resultsContainer} ${styles.noData}`}>
            Not Found
          </div>
        )}
    </div>
  );
};

export default SearchBar;
