// src/Avatar.js
import React from "react";
import PropTypes from "prop-types";
import styles from "../styles/avatar.module.css";

const Avatar = ({ src, alt, size, borderColor }) => {
  const customStyle = {
    width: size,
    height: size,
    border: `2px solid ${borderColor}`,
  };

  return (
    <img src={src} alt={alt} style={customStyle} className={styles.avatar} />
  );
};

Avatar.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderColor: PropTypes.string,
};

Avatar.defaultProps = {
  alt: "avatar",
  size: 50,
  borderColor: "#000",
};

export default Avatar;
