import React, { useState } from "react";
import { GoHome } from "react-icons/go";
import { LuMessagesSquare } from "react-icons/lu";
import { PiRobotLight } from "react-icons/pi";
import { TbStack2 } from "react-icons/tb";
import { FiSettings } from "react-icons/fi";
import { IoLogOutOutline } from "react-icons/io5";
import styles from "../styles/leftNavBar.module.css";
import logo from "../assets/logo.png";
import { useDispatch } from "react-redux";
import { logoutUser } from "../store";
import { toast } from "react-toastify";

const LeftNavBar = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("chat");

  const handleLogout = () => {
    dispatch(logoutUser());
  };
  const showAlertMessage = () => {
    toast.dismiss();
    toast.clearWaitingQueue();
    toast.info("Feature will Come soon");
  };

  return (
    <div className={styles.left_nav_bar}>
      <div className={styles.logo_container}>
        <img src={logo} alt="Logo" className={styles.logo} />
      </div>

      <div className={styles.nav_tabs}>
        <button
          className={`${styles.nav_tab} ${
            activeTab === "home" ? styles.active : ""
          }`}
          onClick={showAlertMessage}
          title="Home"
        >
          <GoHome />
          <span className={styles.tab_text}>Home</span>
        </button>
        <button
          className={`${styles.nav_tab} ${
            activeTab === "chat" ? styles.active : ""
          }`}
          onClick={() => setActiveTab("chat")}
          title="Chat"
        >
          <LuMessagesSquare />
          <span className={styles.tab_text}>Chat</span>
        </button>
        <button
          className={`${styles.nav_tab} ${
            activeTab === "bot" ? styles.active : ""
          }`}
          onClick={showAlertMessage}
          title="Bot"
        >
          <PiRobotLight />
          <span className={styles.tab_text}>Bot</span>
        </button>
        <button
          className={`${styles.nav_tab} ${
            activeTab === "stack" ? styles.active : ""
          }`}
          onClick={showAlertMessage}
          title="Stack"
        >
          <TbStack2 />
          <span className={styles.tab_text}>Stack</span>
        </button>
      </div>

      <div className={styles.bottom_icons}>
        <button className={styles.nav_tab} title="Settings">
          <FiSettings />
          <span className={styles.tab_text} onClick={showAlertMessage}>
            Settings
          </span>
        </button>
        <button
          className={styles.nav_tab}
          onClick={() => handleLogout()}
          title="Logout"
        >
          <IoLogOutOutline />
          <span className={styles.tab_text}>Logout</span>
        </button>
      </div>
    </div>
  );
};

export default LeftNavBar;
