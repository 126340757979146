import React from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import styles from "../styles/filter.module.css";

dayjs.extend(utc);
dayjs.extend(quarterOfYear);

const Filter = ({ selectedOption, filterHandler }) => {
  const calculateDateRange = (option) => {
    let endDate = dayjs().utc().format(); // Default end date is now
    let startDate = null;

    switch (option) {
      case "2 Years":
        startDate = dayjs().utc().subtract(2, "year").format();
        break;
      case "1 Year":
        startDate = dayjs().utc().subtract(1, "year").format();
        break;
      case "Last Quarter":
        // Last quarter's start and end date
        startDate = dayjs()
          .utc()
          .subtract(1, "quarter")
          .startOf("quarter")
          .format();
        endDate = dayjs()
          .utc()
          .subtract(1, "quarter")
          .endOf("quarter")
          .format();
        break;
      case "This Quarter":
        // This quarter's start date and end date (up to now)
        startDate = dayjs().utc().startOf("quarter").format();
        break;
      case "7 Days":
        startDate = dayjs().utc().subtract(7, "day").format();
        break;
      case "All Time":
        startDate = null;
        break;
      default:
        startDate = null;
        break;
    }

    return { start_date: startDate, end_date: endDate };
  };

  const handleOptionChange = (event) => {
    const option = event.target.value;
    const dateRange = calculateDateRange(option);
    filterHandler({ option, dateRange });
  };

  const options = [
    "All Time",
    "2 Years",
    "1 Year",
    "Last Quarter",
    "This Quarter",
    "7 Days",
  ];

  return (
    <div className={styles.container}>
      <div className={styles.radioGroup}>
        {options.map((option, index) => (
          <React.Fragment key={option}>
            <label className={styles.radioOption}>
              <input
                type="radio"
                value={option}
                checked={selectedOption === option}
                onChange={handleOptionChange}
                className={styles.radioButton}
              />
              {option}
            </label>
            {index !== options.length - 1 && <hr className={styles.hr} />}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Filter;
