import { useCallback, useState } from "react";
import { useResizeObserver } from "@wojtekmaj/react-hooks";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { LuZoomOut, LuZoomIn } from "react-icons/lu";
import { MdClose } from "react-icons/md";

import styles from "./pdfViewer.module.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const resizeObserverOptions = {};

const maxWidth = 800;

const PdfViewer = ({ fileLink, handleClosePDF }) => {
  const [numPages, setNumPages] = useState();
  const [containerRef, setContainerRef] = useState(null);
  const [containerWidth, setContainerWidth] = useState();
  const [scale, setScale] = useState(1);

  const onResize = useCallback((entries) => {
    const [entry] = entries;

    if (entry) {
      setContainerWidth(entry.contentRect.width);
    }
  }, []);

  useResizeObserver(containerRef, resizeObserverOptions, onResize);

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  const handleZoomIn = () => {
    setScale((prevScale) => Math.min(prevScale + 0.1, 2));
  };

  const handleZoomOut = () => {
    setScale((prevScale) => Math.max(prevScale - 0.1, 0.5));
  };

  return (
    <>
      <div className={styles.pdf_viewer__info}>
        <h2>PDF Viewer</h2>
        <div className={styles.pdf_viewer__controls}>
          <LuZoomIn onClick={handleZoomIn} size={20} />
          <LuZoomOut onClick={handleZoomOut} size={20} />

          <span className={styles.pdf_close_btn}>
            <MdClose onClick={handleClosePDF} size={20} />
          </span>
        </div>
      </div>
      <div className={styles.pdf_view} ref={setContainerRef}>
        <Document file={fileLink} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              width={
                containerWidth
                  ? Math.min(containerWidth, maxWidth) * scale
                  : maxWidth * scale
              }
            />
          ))}
        </Document>
      </div>
    </>
  );
};

export default PdfViewer;
