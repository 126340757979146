import { createSlice } from "@reduxjs/toolkit";
import { getAllChats } from "../actions/getAllChats";
import { getChatHistory } from "../actions/getChatHistory";
import { sendMessage } from "../actions/sendMessage";

const initialState = {
  allChats: [],
  activeChat: null,
  isChatMessagesLoading: false,
  isMessageSending: false,
  error: null,
};

const chatsSlice = createSlice({
  name: "chats",
  initialState,

  reducers: {
    addUserMessage: (state, action) => {
      if (state.activeChat) {
        state.activeChat.messages.push(action.payload);
      } else {
        state.activeChat = {
          messages: [action.payload],
        };
      }
      state.isMessageSending = true;
    },
    clearActiveChat: (state) => {
      state.activeChat = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllChats.fulfilled, (state, action) => {
        state.allChats = action.payload.chats;
      })

      .addCase(getChatHistory.pending, (state) => {
        state.isChatMessagesLoading = true;
        state.error = null;
      })
      .addCase(getChatHistory.fulfilled, (state, action) => {
        state.isChatMessagesLoading = false;

        // Setting activeChat with all relevant data from the fetched chat history
        state.activeChat = {
          chat_id: action.payload.chat_id,
          chat_title: action.payload.chat_title,
          total_messages: action.payload.total_messages,
          messages: action.payload.messages,
          documents_used: action.payload.documents_used,
        };
      })

      .addCase(sendMessage.fulfilled, (state, action) => {
        state.isMessageSending = false;

        const { data, isNewChat } = action.payload;

        if (state.activeChat && state.activeChat.chat_id === data.chat_id) {
          // Update the existing active chat with the new message
          state.activeChat.messages.push(data.messages[1]);
        } else {
          // Initialize a new active chat
          state.activeChat = {
            chat_id: data.chat_id,
            chat_title: data.auto_generated_name,
            messages: data.messages,
            documents_used: data.documents_used,
            total_messages: data.messages.length,
            creation_date: data.creation_date,
          };
        }

        if (isNewChat) {
          const currentMonthValue = data.creation_month;

          const existingMonth = state.allChats.find(
            (chatMonth) => chatMonth.monthValue === currentMonthValue
          );

          const newChatData = {
            chat_id: data.chat_id,
            auto_generated_name: data.auto_generated_name,
            creation_date: data.creation_date,
            creation_month: data.creation_month,
            documents: data.documents_used,
          };

          if (existingMonth) {
            existingMonth.data.unshift(newChatData);
          } else {
            state.allChats.unshift({
              monthName: new Date(data.creation_date).toLocaleString(
                "default",
                {
                  month: "long",
                  year: "numeric",
                }
              ),
              monthValue: currentMonthValue,
              data: [newChatData],
            });
          }
        }
      })

      .addCase(sendMessage.rejected, (state) => {
        state.isMessageSending = false; // Reset loading state on error
      });
  },
});

export const { addUserMessage, clearActiveChat } = chatsSlice.actions;

export default chatsSlice.reducer;
