import { createSlice } from "@reduxjs/toolkit";
import { clearActiveChat } from "./chatsSlice";

const initialState = {
  // allStocks: [],
  selectedStock: null,
  loading: false,
  error: null,
};

const stocksSlice = createSlice({
  name: "stock",
  initialState,
  reducers: {
    setSelectedStock(state, action) {
      state.selectedStock = action.payload;
      clearActiveChat();
    },
  },
});

export const { setSelectedStock } = stocksSlice.actions;
export default stocksSlice.reducer;
