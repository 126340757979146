import "./index.css";

import { BsSendFill } from "react-icons/bs";

const SuggestionBox = ({ onItemPressHandler, questions }) => {
  const SuggestionItem = ({ data, lastItem }) => {
    return (
      <div className="suggestion-item" onClick={() => onItemPressHandler(data)}>
        <p>{data}</p>
        <BsSendFill color="#4F46E5" />
      </div>
    );
  };
  return (
    <div className="suggestion-container">
      {questions?.map((item, index) => (
        <SuggestionItem
          key={index}
          data={item}
          lastItem={questions?.length - 1 === index}
        />
      ))}
    </div>
  );
};

export default SuggestionBox;
