import React from "react";
import LeftNavBar from "../../components/LeftNavBar";
import SearchBar from "../../components/SearchBar";
import ChatHistory from "../../components/chatSectionComponents/chatHistory";
import MyData from "../../components/chatSectionComponents/myData";
import ChatHeader from "../../components/chatSectionComponents/chatHeader";
import ChatWindow from "../../components/chatSectionComponents/chatWindow";
import PdfViewer from "../../components/chatSectionComponents/pdfViewer";
import styles from "./chat.module.css";
import useChat from "./useChat";

const Chat = () => {
  const {
    handleSearchStockName,
    handleShowPDF,
    isShowingPDF,
    fileLink,
    handleClosePDF,
  } = useChat();

  return (
    <div className={styles.container}>
      <div>
        <LeftNavBar />
      </div>
      <div className={styles.mainContent}>
        <div className={styles.header}>
          <SearchBar
            placeholder="Start Typing Stock Name..."
            width="500px"
            onSelect={handleSearchStockName}
          />
        </div>
        <div className={styles.content}>
          <div
            className={`${styles.leftPanel} ${
              isShowingPDF ? styles.leftPanelHidden : styles.leftPanelShow
            }`}
          >
            <div className={styles.asideContainer}>
              <ChatHistory />
              <MyData handleShowPDF={handleShowPDF} />
            </div>
          </div>
          <div className={styles.rightPanel}>
            <ChatHeader />
            <ChatWindow />
          </div>
          {isShowingPDF && (
            <div
              style={{
                border: "1px solid #EAECF0",
                width: "100% ",
                overflow: "auto",
              }}
            >
              <PdfViewer fileLink={fileLink} handleClosePDF={handleClosePDF} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Chat;
