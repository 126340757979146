export const SCREEN_WIDTH = window.innerWidth;

export const FILE_OPTIONS = [
  { value: "broker_research", label: "Broker Research" },
  { value: "earning_calls", label: "Earning Calls" },
  { value: "financials", label: "Financials" },
  { value: "expert_calls", label: "Expert Calls" },
  { value: "others", label: "Others" },
];
export const FORM_LINK = "https://forms.gle/yG7WxgK1VUPx2mvi9";
